<template>
  <land-section
    id="activity_putin"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 1200px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
          sm="12"
        >
          <v-card
            color="white"
            flat
            tile
            height="700"
            class="overflow-y-auto overflow-x-hidden rounded px-6 py-12"
          >
            <upload-images
              ref="wdUpload"
              :config="upConfig"
              @change="onUploadImagesChange"
            />
          </v-card>

          <div class="co-flex-row co-justify-between co-items-center co-w-full pa-2">
            <span class="blue-grey--text">
              {{ imgTip }}
            </span>
            <!-- <span class="blue-grey--text">
              {{ `${imgCount} / ${upConfig.limit}` }}
            </span> -->
            <span
              v-if="upImages.length"
              class="blue-grey--text"
            >
              {{ upImages.length }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <div class="co-flex-col mx-2">
            <span class="text-subtitle-1">上传活动参赛作品</span>
            <div class="co-w24 co-h4 primary my-1" />
            <span class="text-h6 my-4">
              {{ params.title }}
            </span>
          </div>
          <v-card
            class="rounded px-4 py-6"
            color="white"
            flat
            tile
            height="595"
          >
            <div class="co-flex-col co-justify-between co-h-full">
              <div
                v-if="frmUpload.state === 'issue'"
                class="co-flex-col"
              >
                <v-form
                  :ref="frmUpload.name"
                  v-model="frmUpload.valid"
                  lazy-validation
                >
                  <v-textarea
                    v-model="txtTitle.value"
                    :rules="txtTitle.rules"
                    :label="txtTitle.label"
                    counter
                    :maxlength="txtTitle.maxlength"
                    :hint="txtTitle.hint"
                    persistent-hint
                    outlined
                    required
                    rows="2"
                  />

                  <v-select
                    v-if="txtTags.items && txtTags.items.length"
                    v-model="txtTags.value"
                    :rules="txtTags.rules"
                    :label="txtTags.label"
                    :items="txtTags.items"
                    outlined
                    class="mt-4"
                  />
                  <v-textarea
                    v-model="txtDesc.value"
                    :label="txtDesc.label"
                    outlined
                  />
                  <div class="co-flex-col mt-1">
                    <span class="blue-grey--text my-2">
                      {{ tipIdentity }}
                    </span>
                    <v-btn
                      :loading="btnSubmit.loading"
                      :disabled="!allowed"
                      block
                      large
                      color="primary"
                      @click="onSubmit"
                    >
                      {{ btnSubmit.text }}
                    </v-btn>
                  </div>
                </v-form>
              </div>
              <div
                v-else
                class="co-flex-col co-justify-center co-items-center py-10"
              >
                <v-icon
                  size="160"
                  :color="resultTip.color || 'grey'"
                >
                  {{ resultTip.icon }}
                </v-icon>
                <span
                  class="co-text-md blue-grey--text lighten-2 mt-4"
                >
                  {{ resultTip.hint }}
                </span>
                <v-btn
                  dark
                  width="180"
                  height="40"
                  :color="resultTip.color"
                  class="mt-8"
                  @click="onNext"
                >
                  {{ resultTip.next }}
                </v-btn>
              </div>

              <div
                v-if="videoable || articlable"
                class="co-flex-row co-justify-between co-items-center co-w-full"
              >
                <v-btn
                  v-if="videoable"
                  width="46%"
                  outlined
                  color="blue"
                  @click="toIssueVideo"
                >
                  {{ btnVideo.text }}
                </v-btn>
                <v-btn
                  v-if="articlable"
                  width="46%"
                  outlined
                  color="indigo"
                  @click="toIssueArticle"
                >
                  {{ btnArticle.text }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dialog-alert ref="dlgAlert" />
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  export default {
    metaInfo: { title: '上传参赛作品' },
    components: {
      UploadImages: () => import('@/pages/sections/my/UploadImages.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
      userInfo: {
        type: Object,
        default: () => {
          return {}
        }
      },
      videoable: {
        type: Boolean,
        default: false
      },
      articlable: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        upConfig: {
          ratio: 1.45,
          numberPerRow: 3,
          limit: 50
        },
        imgCount: 0,
        imgTip: '只能上传jpg/png/gif文件，且每个文件不能超过20MB',
        btnSubmit: {
          loading: false,
          text: '提交图片作品'
        },
        btnVideo: {
          text: '提交视频作品'
        },
        btnArticle: {
          text: '提交图文作品'
        },
        allowIdentitys: [
          '医学生',
          '医务工作者'
        ],
        tipIdentity: '仅限于医学生及医务工作者投稿',
        allowed: true,
        tips: {
          success: {
            value: 'success',
            icon: 'mdi-check-circle-outline',
            color: 'success',
            hint: '作品提交成功',
            next: '返回活动页面',
            handler: this.toBack
          },
          error: {
            value: 'error',
            icon: 'mdi-close-circle-outline',
            color: 'error',
            hint: '作品提交失败',
            next: '重新提交',
            handler: this.toReissue
          }
        },
        resultTip: {},
        txtTitle: {
          value: '',
          label: '标题',
          hint: '本次参赛作品的标题',
          maxlength: 64,
          rules: [
            v => !!v || '必须填写标题',
            v => (v && v.length <= 64) || '标题不能超过 64 个字符',
          ]
        },
        txtTags: {
          label: '作品所属标签',
          value: '',
          items: [],
          rules: []
        },
        txtDesc: {
          value: '',
          label: '描述'
        },
        tagRules: [
          v => !!v || '必须选择所属标签',
        ],
        upImages: [],
        frmUpload: {
          name: 'form_upload',
          valid: true,
          state: 'issue'
        },
      }
    },
    watch: {
      params: {
        handler (val) {
          const temp = val.imageTags || ''
          this.txtTags.items = web.comm.parseTags(temp, false)

          if (this.txtTags.items.length > 0) {
            this.txtTags.value = this.txtTags.items[0]
            this.txtTags.rules = this.tagRules
          }
        },
        immediate: true
      },
      userInfo: {
        handler (val) {
          const identity = val.identity || ''
          if (this.allowIdentitys.indexOf(identity) !== -1) {
            this.allowed = true
          } else {
            this.allowed = false
          }
        },
        immediate: true
      }
    },
    created () {
    },
    methods: {
      toIssueVideo () {
        this.$emit('change-mode', 'video')
      },
      toIssueArticle () {
        this.$emit('change-mode', 'article')
      },
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      uploadFiles () {
        const frm = this.$refs.wdUpload
        if (frm) {
          frm.submit()
        }
      },
      resetForm () {
        const frm = this.$refs[this.frmUpload.name]
        if (frm) {
          frm.reset()
        }
      },
      checkWidget () {
        const result = []
        const frm = this.$refs[this.frmUpload.name]
        if (!frm) {
          return
        }
        if (!frm.validate()) {
          result.push('填写参赛作品标题')
        }

        if (this.upImages.length < 1) {
          result.push('上传需要参赛的作品')
        }

        if (result.length) {
          return result.join('，')
        } else {
          return ''
        }
      },
      setResultTip (val) {
        const tip = this.tips[val] || {}
        this.resultTip = tip
        this.frmUpload.state = tip.value || 'issue'
      },
      toBack () {
        api.page.navigateBy(this, 'activityDetail', this.params)
      },
      toReissue () {
        const result = this.checkUploading()
        if (result.ready || result.error) {
          this.uploadFiles()
        } else {
          this.issueContent()
        }
      },
      onNext () {
        const handler = this.resultTip.handler
        if (typeof handler === 'function') {
          handler()
        }
      },
      checkIdentity () {
        let result = false
        const identity = this.userInfo.identity || ''
        if (this.allowIdentitys.indexOf(identity) !== -1) {
          result = true
        } else {
          result = false
        }
        return result
      },
      onSubmit () {
        if (!this.checkIdentity()) {
          this.showAlert('warning', this.tipIdentity)
          return
        }
        const message = this.checkWidget()
        if (message) {
          this.showAlert('warning', '必须完善作品或标题')
        } else {
          this.btnSubmit.loading = true
          this.uploadFiles()
        }
      },
      getData () {
        const thumbs = []

        for (const i in this.upImages) {
          const obj = this.upImages[i]
          const resp = obj.response || {}
          const data = resp.data || {}
          if (data.fileUrl) {
            thumbs.push(data.fileUrl)
          }
        }

        const cover = thumbs[0]
        const result = {
          activityId: this.params.activityId,
          userId: this.userInfo.userId,
          title: this.txtTitle.value,
          activityTag: this.txtTags.value,
          description: this.txtDesc.value,
          cover,
          type: web.comm.ContentTypes.IMAGE,
          state: web.comm.EnrollStates.NOPASS,
          accessScope: web.comm.Scopes.PUBLIC,
          thumbs: JSON.stringify(thumbs)
        }

        return result
      },
      issueContent () {
        const me = this
        const data = this.getData()
        const executed = function (res) {
          me.btnSubmit.loading = false
          if (res.status) {
            me.setResultTip('success')
            me.showAlert('success', '作品提交成功')
          } else {
            me.setResultTip('error')
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.activityEnroll.create({
          data,
          executed
        })
      },
      checkUploading () {
        const total = this.upImages.length
        let ready = 0
        let success = 0
        let error = 0
        for (const i in this.upImages) {
          const src = this.upImages[i]
          if (src.status === 'success') {
            success += 1
          } else if (src.status === 'ready') {
            ready += 1
          } else {
            error += 1
          }
        }

        return {
          ready, success, error, total
        }
      },
      checkIssue () {
        const result = this.checkUploading()
        if (result.ready < 1) {
          this.issueContent()
        }
      },
      onUploadImagesChange (ev) {
        // console.log({ ev })
        this.imgCount = ev.count
        this.upImages = ev.items

        this.checkIssue()
      },
    }
  }
</script>
